<template>
  <v-row justify="center">
    <v-dialog
      v-model="isSubmitTransferModalOpen"
      persistent
      max-width="360"
    >
      <v-card>
        <v-card-title class="text-h4 font-weight-medium">
          {{ $t("submit_transfer") }}
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scroll-y-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="expected_delivery_date"
                  outlined
                  label="Delivery date"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  :rules="[rules.required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                class="my-0 py-2"
                v-model="expected_delivery_date"
                no-title
                :min="getTodayDate()"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
            <v-textarea
              v-model="note"
              append-icon="mdi-note-text-outline"
              outlined
              no-resize
              label="Note"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            outlined
            small
            color="indigo"
            text
            @click="toggleSubmitTransfer"
          >
            Cancel
          </v-btn>
          <v-btn
            class="primary"
            text
            small
            @click="submitTransfer()"
            :loading="isLoading"
            :disabled="isLoading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import service from "@/store/services/transfer-product-service";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    isSubmitTransferModalOpen: Boolean,
    toggleSubmitTransfer: Function,
    currentTransfer: Object,
  },
  data() {
    return {
      valid: false,
      menu: null,
      expected_delivery_date: null,
      note: "",
      isLoading: false,
    };
  },

  computed: {
    console: () => console,
  },

  methods: {
    getTodayDate() {
      return new Date().toJSON().slice(0, 10);
    },

    async submitTransfer() {
      this.validate();
      if (this.valid) {
        this.isLoading = true;
        try {
          const submittedTransfer = await service.submitTransfer({
            transfer_id: this.currentTransfer.id,
            expected_delivery_date: this.expected_delivery_date,
            note: this.note,
          });

          const BDC = await service.getDeliveryNote(this.currentTransfer);
          const url = window.URL.createObjectURL(new Blob([BDC]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${submittedTransfer.tracking_number}.pdf`
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.$store.commit("transferProducts/TRANSFER", {});
          this.$store.commit("products/CLEAN_LIST");
          this.$swal("", "Transfer created successfully", "success");
          this.$store.commit("transferProducts/CURRENT_TAB", 1);
          this.$router.push("/transfers");
        } catch (error) {
          this.isLoading = false;
          console.log(error);
          this.$store.dispatch("alerts/error", error.response.data.message);
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
  },
  mounted() {
    if (this.currentTransfer.expected_delivery_date) {
      this.date = this.currentTransfer.expected_delivery_date;
    }
  },
};
</script>